<div class="cbw-group-appointment-wrapper">
  @if (formReseted) {
    <app-alert
      (click)="formReseted = false"
      text="{{ 'common.resetSuccess' | translate }}"
    />
  }

  @if (showFullscreenOverlay) {
    <div class="overlay text-center">
      <div class="content">
        <p
          class="overlay-helper-text cbw-overlay-helper-text font-weight-light"
          [innerHtml]="('summary_page_translations.saferpay_payment_overlay' | translate) | trustHtml"
          >
        </p>
        <button #button class="btn btn-lg btn-warning mt-2" (click)="closeSaferpayOverlay()">
          {{ 'common.close' | translate }}
        </button>
      </div>
    </div>
  }

  <div id="event-component" #eventsSection>
    @if (booked) {
      <div>
        @if (!showBookingLimitReachedMessageOnFinalPage && !isWaitingListBookingMessageEnabled) {
          <app-finalpage
            [lang]="lang"
            [success]="finalPage"
            [successMsg]="successMessage"
            [successTitle]="'events.finalpage.successTitle' | translate"
            [errTitle]="'events.finalpage.errorTitle' | translate"
            [widgetBookingInfo]="widgetBookingInfo"
            isWaitingListBooking="false"
            [errMsg]="'events.finalpage.errorMessage' | translate"
            [manageAppointmentNowTemplate]="manageAppointmentNowTemplate"
            [downloadBookingDetailTemplate]="downloadBookingDetailTemplate"
            [pageType]="eventConstants.EVENTS"
            [successFullBookings]="successFullBookings"
          />
        }
        @if (showBookingLimitReachedMessageOnFinalPage && !isWaitingListBookingMessageEnabled) {
          <app-finalpage
            [lang]="lang"
            [success]="finalPage"
            [successMsg]="successMessage"
            [successTitle]="'events.finalpage.successTitle' | translate"
            [errTitle]="'events.finalpage.errorTitle' | translate"
            [widgetBookingInfo]="widgetBookingInfo"
            [bookingLimitReached]="true"
            isWaitingListBooking="false"
            [errMsg]="'events.finalpage.errorMessage' | translate"
            [widgetSuccessPageEventBookingLimitReachedTemplate]="widgetSuccessPageEventBookingLimitReachedTemplate"
            [manageAppointmentNowTemplate]="manageAppointmentNowTemplate"
            [downloadBookingDetailTemplate]="downloadBookingDetailTemplate"
            [pageType]="eventConstants.EVENTS"
            [successFullBookings]="successFullBookings"
          />
        }
        @if (isWaitingListBookingMessageEnabled) {
          <app-finalpage
            [lang]="lang"
            [success]="finalPage"
            [successMsg]="'alert_messages.waitinglist_booking_message' | translate"
            [successTitle]="'events.finalpage.successTitle' | translate"
            [errTitle]="'events.finalpage.errorTitle' | translate"
            [errMsg]="'events.finalpage.errorMessage' | translate"
            [widgetBookingInfo]="widgetBookingInfo"
            isWaitingListBooking="true"
            [manageAppointmentNowTemplate]="manageAppointmentNowTemplate"
            [downloadBookingDetailTemplate]="downloadBookingDetailTemplate"
            [pageType]="eventConstants.EVENTS"
            [successFullBookings]="successFullBookings"
          />
        }
        <div class="additional-message mt-4">
          @if (hideBookingAgainButton()) {
            <calenso-button
              id="cbw-book-next-appointment-button"
              #button
              text="{{ 'appointments.finalpage.bookNextAppointment' | translate }}"
              [widgetColorConf]="widgetColorConf"
              (clickEvent)="booked = false; resetLandingPageLink(); navigateTo(eventConstants.EVENTS)"
            />
          }
          @if (!finalPage) {
            <calenso-button
              [id]="'cbw-book-appointment-again-button'"
              #button
              text="{{ 'appointments.finalpage.bookAppointmentAgain' | translate }}"
              (clickEvent)="booked = false; resetLandingPageLink(); navigateTo(eventConstants.EVENTS)"
              [widgetColorConf]="widgetColorConf"
            />
          }
          @if (widgetConf.subscription.subscription_type_id < widgetConstant.SUBSCRIPTION_WHITELABEL_TYPE.id) {
            <div class="mt-4">
              <img alt="Calenso Logo" width="100" src="{{ environment.deployUrl }}/assets/images/{{ environment.footerLogoUrl }}" />
            </div>
          }
        </div>
      </div>
    }

    <ng-template #loading>
      @if (noEvents) {
        <app-loader [loaderType]="eventConstants.EVENT_LOADER" />
      }
    </ng-template>
    <br />
    @if (!booked) {
      <div>
        <div class="wizard-navigation">
          <app-event-navigation-bar
            [viewMode]="viewMode"
            [events]="events"
            [eventState]="eventState"
            [cart]="cart"
            [lang]="lang"
            [partner]="partner"
            [hideSlotStep]="hideSlotStep"
            (navigateToEvent)="navigateTo($event)"
            [showAds]="showAds"
            [widgetColorConf]="widgetColorConf"
            [widgetTemplates]="widgetTemplates"
            [globals]="globals"
            [supportedWidgetLanguages]="partner.supported_widget_languages"
            [langSwitcher]="langSwitcher"
          />
        </div>
        <div class="widget">
          <div class="wizard-container">
            <div class="" data-color="blue" id="wizardProfile">
              <form #eventForm="ngForm">
                <div class="wizard-header">
                  <h3 class="wizard-title"></h3>
                </div>
                <div>
                  @switch (viewMode) {
                    <!-- ////// TAB 1 ////// -->
                    @case (eventConstants.EVENTS) {
                      <div>
                        <app-event-card
                          [noEvents]="noEvents"
                          [globalEventDisabled]="globalEventDisabled"
                          [showEventsListing]="showEventsListing"
                          [events]="events"
                          [rawEvents]="events"
                          [shownMsg]="shownMsg"
                          [eventState]="eventState"
                          [widgetColorConf]="widgetColorConf"
                          [screenType]="screenType"
                          [noActiveEvents]="noActiveEvents"
                          [partner]="partner"
                          [lang]="lang"
                          [widgetTemplates]="widgetTemplates"
                          [hideGridSwitcher]="hideGridSwitcher"
                          [filterStores]="filterStores"
                          [filterCategories]="filterCategories"
                          [paginationData]="paginationData"
                          (navigateToEvent)="hideSlotStep=false; navigateTo($event);"
                          (resetFormEvent)="resetForm()"
                          (autoSelectSlotAndJumpToSummaryEvent)="onAutoSelectSlotAndJumpToSummaryEvent()"
                          (loadMoreEvents)="loadMoreEvents($event)"
                        />
                      </div>
                    }
                    <!-- ////// TAB 2 ////// -->
                    @case (eventConstants.DATE) {
                      @if (this.eventState.is_agenda_view_enabled === 1) {
                        <h4 class="cbw-choose-date-title">{{ dateLabelTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}</h4>
                        <app-event-agenda-view
                          [eventState]="eventState"
                          [noFreeSlots]="noFreeSlots"
                          [calendarLoaded]="calendarLoaded"
                          [eventOnYear]="eventOnYear"
                          [bookedItems]="bookedItems"
                          [cart]="cart"
                          [selectedDate]="agendaViewSelectedDate"
                          (updateCartEvent)="updateCart($event)"
                          (viewYearChangedEvent)="viewYearChanged($event)"
                          (gotoNextPageEvent)="navigateTo('summary')"
                        />
                      } @else {
                        <div class="row cb-event-calendar-slot-block">
                          @if (!noFreeDates) {
                            <div class="col-sm-12">
                              <h4 class="cbw-choose-date-title">{{ dateLabelTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}</h4>
                            </div>
                          }
                          <div class="col-lg-6 col-md-6 col-sm-12 cbw-calender-picker-wrapper">
                            <app-calendar-picker
                              [noFreeDates]="noFreeDates"
                              [calendarSlotsCountList]="calendarSlotsCountList"
                              [disablePrev]="disablePrev"
                              [(viewDate)]="viewDate"
                              [futureBookingThreshold]="partner.future_booking_threshold_events"
                              [calendarLoaded]="calendarLoaded"
                              [days]="days"
                              [partner]="partner"
                              (viewDateChangedEvent)="viewDateChanged($event)"
                              (dayClickedEvent)="eventClicked($event)"
                              [widgetColorConf]="widgetColorConf"
                              (markExceptionDaysEvent)="markScheduleForEvent($event)"
                            />
                          </div>
                          <div class="col-lg-6 col-md-6 col-xs-6 col-xxs-12 termin-container cbw-appointment-slots-wrapper">
                            @if (!this.eventState?.date && !showBookableDateNotAvailableIssue && calendarDate) {
                              <div role="alert" class="alert cbw-date-select-hint-alert">
                                {{ 'alert_messages.select_day_in_calendar_msg' | translate }}
                              </div>
                            }
                            @if (showBookableDateNotAvailableIssue && calendarDate) {
                              @if (!automationBookingDatesLimitReached) {
                                <div role="alert" class="no-bookable-error-message">
                                  {{ 'alert_messages.no_slots_dates_message' | translate: {month: (viewDate | date:'MMMM' | translate)} }}
                                </div>
                              }
                              @if (automationBookingDatesLimitReached) {
                                <div role="alert" class="no-bookable-error-message">
                                  {{ 'alert_messages.no_slots_dates_reached_message' | translate: {month: (viewDate | date:'MMMM' | translate)} }}
                                </div>
                              }
                              @if (!automationBookingDatesLimitReached) {
                                <div class="mt-2">
                                  <calenso-button
                                    #button
                                    (clickEvent)="autoLoadBookableDates()"
                                    [widgetColorConf]="widgetColorConf"
                                    placement="left"
                                    [text]="'buttons.yes_search' | translate"
                                  />
                                </div>
                              }
                            }
                            @if (!showBookableDateNotAvailableIssue && this.eventState?.date) {
                              <app-event-slot
                                [eventState]="eventState"
                                [terminLoaded]="terminLoaded"
                                [eventOnDate]="eventOnDate"
                                [bookedItems]="bookedItems"
                                [widgetTemplates]="widgetTemplates"
                                [lang]="lang"
                                [cart]="cart"
                                [noEvents]="noEvents"
                                (updateCartEvent)="updateCart($event)"
                                (gotoNextPageEvent)="navigateTo('summary')"
                              />
                            }
                          </div>
                        </div>
                      }
                      @if (!showBookableDateNotAvailableIssue) {
                        <div class="wizard-footer">
                          <div class="row cbw-responsive-logo-footer">
                            <div
                              class="col-12 col-sm-7 order-last order-sm-first pt-sm-2">
                              @if (widgetConf.subscription.subscription_type_id < widgetConstant.SUBSCRIPTION_WHITELABEL_TYPE.id) {
                                <div class="footer-image-col">
                                  <img src="{{ environment.deployUrl }}/assets/images/{{ environment.footerLogoUrl }}" alt="Calenso Logo" width="100" />
                                </div>
                              }
                            </div>
                            <div class="col-12 col-sm-5 footer-btn-col">
                              @if (partner.hide_back_on_bookingsite == 0) {
                                <calenso-button
                                  [id]="'cbw-prev-button'"
                                  [text]="previousButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang"
                                  (clickEvent)="navigateTo(eventConstants.EVENTS)"
                                  placement="left"
                                  [widgetColorConf]="widgetColorConf"
                                />
                              }
                              <calenso-button
                                [id]="'cbw-next-button'"
                                class="ms-2"
                                [text]="nextButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang"
                                (clickEvent)="navigateTo(eventConstants.SUMMARY)"
                                [disabled]="cart.length <= 0"
                                placement="right"
                                [widgetColorConf]="widgetColorConf"
                              />
                            </div>
                          </div>
                        </div>
                      }
                    }
                    <!-- ////// TAB 3 ////// -->
                    @case (eventConstants.SUMMARY) {
                      <div>
                        <div class="row inline-block">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-12 mt-4 cbw-personal-data-wrapper">
                            @if (!isCustomFieldsLoaded) {
                              <app-cw-new-card customCardClass="mb-4" [showBody]="true" [showHeader]="true">
                                <div header>
                                  <app-cw-card-header title="" subtitle=""/>
                                </div>
                                <div body class="my-4">
                                  <div class="skeleton-box-card">
                                    <div class="row">
                                      @for (appointmentSlotGhostElement of appointmentSlotGhostElements; track appointmentSlotGhostElement) {
                                        <div class="col-md-12 mb-4">
                                          <div class="d-flex stats-card">
                                            <div class="skeleton-animation w-100 d-flex justify-content-center flex-column">
                                              <div>
                                                <div class="stat-box-2"></div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </app-cw-new-card>
                            }
                            @if (isCustomFieldsLoaded) {
                              <app-personal-data-form
                                [cart]="cart"
                                [(userState)]="userState"
                                [globals]="globals"
                                [customFields]="eventCustomFields"
                                [partner]="partner"
                                [(customFieldValues)]="customFieldValues"
                                [eventState]="eventState"
                                (updateUserStateEvent)="updateUserState($event)"
                                (updateCustomerSelectedEvent)="updateCustomerSelected($event)"
                                (updateAdditionalGuestsEvent)="updateAdditionalGuests($event)"
                                [type]="eventConstants.EVENTS"
                                [lang]="lang"
                                [hideLoginOnBookingSite]="partner.hide_login_on_bookingsite"
                                [courtesyForm]="courtesyForm"
                                [widgetTemplates]="widgetTemplates"
                                [userDataLSNameKey]="USER_LS_NAME"
                                [customFieldValuesLSNameKey]="CUSTOMFIELDS_LS_NAME"
                                [selectedJsonCustomerDetail]="selectedJsonCustomerDetail"
                                [meeting_type_id]="meeting_type_id"
                                [customer_notification_preference]="customer_notification_preference"
                              />
                            }
                          </div>
                          <!-- Start of payment method container -->
                          @if (totalCartPrice > 0 && isEventOnlinePaymentEnabled === 1) {
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                              <app-cw-payment-methods
                                [courtesyForm]="courtesyForm"
                                [priceAfterCouponReduction]="priceAfterCouponReduction"
                                [totalAmount]="totalCartPrice"
                                [isStripeEnabled]="isStripeEnabled"
                                [isOnlinePaymentMandatory]="isOnlinePaymentMandatory"
                                [isBexioEnabled]="isBexioEnabled"
                                [isStoreEnabled]="isStoreEnabled"
                                [isLexOfficePaymentTypeEnabled]="isLexOfficePaymentTypeEnabled"
                                [isPaypalEnabled]="isPaypalEnabled"
                                [isSaferpayEnabled]="isSaferpayEnabled"
                                [paypalSetting]="paypalSetting"
                                [(paymentType)]="paymentType"
                                [additionalGuests]="additionalGuests"
                                [lang]="lang"
                                [partner]="partner"
                                [widgetTemplates]="widgetTemplates"
                                [eventCart]="cart"
                                [widgetType]="widgetConstant.EVENT"
                                (paypalSuccessfulEvent)="onPaypalSuccessfulEvent($event)"
                                (resetPaypalEvent)="onResetPaypalEvent($event)"
                                (stripeSuccessfulEvent)="onStripeSuccessfulEvent($event)"
                                (resetStripeTokenEvent)="onResetStripeTokenEvent($event)"
                                (couponSuccessEvent)="onCouponSuccessEvent($event)"
                                (couponFailedEvent)="onCouponFailedEvent($event)"
                                (changePaymentTypeEvent)="onChangePaymentType($event)"
                                (saferpaySuccessfulEvent)="onSaferpaySuccessfulEvent($event)"
                                (resetSaferpayEvent)="onResetSaferpayEvent($event)"
                              />
                            </div>
                          }
                          <!-- End of payment method container -->
                          <div class="col-lg-12 col-md-12 col-sm-12 col-12 mt-4 cbw-appointment-cart-overview-wrapper">
                            <app-event-cart-overview
                              [additionalGuests]="additionalGuests"
                              [courtesyForm]="courtesyForm"
                              [cart]="cart"
                              [lang]="lang"
                              [partner]="partner"
                              [coupon]="coupon"
                              [eventState]="eventState"
                              [finalCouponDiscount]="finalCouponDiscount"
                              [(totalCartDuration)]="totalCartDuration"
                              [(totalCartPrice)]="totalCartPrice"
                              (removeFromCartEvent)="removeFromCart($event)"
                              [cartLsName]="CART_LS_NAME"
                              [widgetTemplates]="widgetTemplates"
                            />
                          </div>
                        </div>
                        <div class="row">
                          @if (bookingErrorMessage) {
                            <div class="col-sm-12 mt-2">
                              <app-alert [text]="bookingErrorMessage" type="danger" />
                            </div>
                          }
                          @if (paymentType === 'paypal' && !paypalOrderDetail) {
                            <div class="col-sm-12 mt-3">
                              <app-alert [text]="'summary_page_translations.paypal_warning_msg' | translate" type="warning" />
                            </div>
                          }
                        </div>
                        <div class="wizard-footer clearfix">
                          <div class="row">
                            <div class="col-12 col-sm-7 order-last order-sm-first pt-sm-2">
                              @if (widgetConf.subscription.subscription_type_id < widgetConstant.SUBSCRIPTION_WHITELABEL_TYPE.id) {
                                <div class="footer-image-col">
                                  <img src="{{ environment.deployUrl }}/assets/images/{{ environment.footerLogoUrl }}" alt="Calenso Logo" width="100" />
                                </div>
                              }
                            </div>
                            <div class="col-12 col-sm-5 footer-btn-col">
                              @if (widgetColorConf) {
                                <calenso-button
                                  [id]="'cbw-prev-button'"
                                  [text]="previousButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang"
                                  (clickEvent)="navigateTo(eventConstants.DATE)"
                                  placement="left"
                                  [widgetColorConf]="widgetColorConf"
                                />
                              }
                              @if (!hideBookingButton) {
                                @if (this.showSaferpayButtonLink) {
                                  <calenso-button
                                    class="ms-2"
                                    #button
                                    [id]="'cbw-booking-finish'"
                                    (clickEvent)="payUsingSaferpay()"
                                    [widgetColorConf]="widgetColorConf"
                                    placement="right"
                                    [text]="bookButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang"
                                  />
                                }
                                @if (widgetColorConf && !this.showSaferpayButtonLink) {
                                  <calenso-button
                                    class="ms-2"
                                    [id]="'cbw-booking-finish'"
                                    [text]="bookButtonTemplate | translation: templateContent.TEMPLATE_CONTENT: lang"
                                    (clickEvent)="bookingStarted = true; book()"
                                    placement="right"
                                    [disabled]="disableBookingButton"
                                    [widgetColorConf]="widgetColorConf"
                                  />
                                }
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  }
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    }
  </div>
</div>
