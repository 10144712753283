@if (isWaitingAlertEnabled()) {
  <app-alert
    text="{{ widgetTemplates | calioMeetingTemplate: { id: 268, type: 'template' } | translation: templateContent.TEMPLATE_CONTENT: lang }}"
    type="warning"
  />
}

@if (eventState.date) {
  <div>
    @if (terminLoaded) {
      @if (eventOnDate?.length > 0) {
        <div>
          @for (eventInfo of ( loadMoreSlots ? eventOnDate: (eventOnDate | slice:0:10) ); track eventInfo; let index =
          $index) {
            <ng-container class="form-input checkbox">
              <div class="label-container d-flex cbw-event-slot-card"
                [ngClass]="{'disabled-input': (!displayRegularData(eventInfo) && !displayWaitingData(eventInfo)) || isSlotBooked( eventInfo.id )}"
                [ngClass]="{'cbw-selected-event-card': isTerminInCart( eventInfo.id )}"
              >
                <div class="w-100 cbw-event-slot-lg">
                  <app-cw-checkbox-radio-button
                    class="w-100"
                    containerClass="ps-0"
                    labelClass="flex-direction-col w-100 hide-custom-control-label"
                    [id]="eventInfo.id"
                    [name]="'freeEvents' + eventInfo.id"
                    [value]="eventInfo.id"
                    [checked]="isTerminInCart(eventInfo.id)"
                    [isCheckbox]="true"
                    [isDisabled]="(!displayRegularData(eventInfo) && !displayWaitingData(eventInfo)) || isSlotBooked(eventInfo.id)"
                    (selectionChange)="updateCart($event.event, eventInfo)"
                  >
                    <span class="event-info-text col-lg-6 ">
                      @if (eventInfo?.title) {
                        {{ eventInfo.title }}
                      }
                      @if (eventState?.is_multi_day === 0) {
                      <div>
                        {{ eventInfo.start | dateutc:timeFormat }} - {{ eventInfo.end | dateutc:timeFormat }}
                      </div>
                      }
                      @if (eventState?.is_multi_day === 1) {
                        <div>
                          @if (checkIfStartEndDatesAreEqual(eventInfo.start, eventInfo.end)) {
                            <span class="value">
                              {{ eventInfo.start | dateutc:datePipeWithComma }} - {{ eventInfo.end | dateutc:timeFormat }}
                            </span>
                          } @else {
                            <span class="value">
                              {{ eventInfo.start | dateutc:dateTimeFormat }} - {{ eventInfo.end | dateutc:dateTimeFormat }}
                            </span>
                          }
                        </div>
                      }
                      @if (eventState?.is_multi_day === 1 && eventInfo?.child_slots?.length > 0) {
                        <div class="cbw-event-info-child-slot">
                          <ul class="ps-3">
                            @for (childSlot of eventInfo.child_slots; track childSlot) {
                              <li class="event-info-child-slot pt-1">
                                {{ childSlot.start | dateutc:datePipeWithComma }} - {{ childSlot.end | dateutc:timeFormat }}
                              </li>
                            }
                          </ul>
                        </div>
                      }

                    </span>

                    @if (!displayRegularData(eventInfo)) {
                      <div class="col-lg-6 event-slot-bookedout">
                        @if (displayWaitingData(eventInfo)) {
                          <span
                            [innerHTML]="('alert_messages.waitinglist_is_available' | translate: {number: (eventInfo.waitinglist_capacity - eventInfo.waitinglist_booking_count)}) | trustHtml">
                          </span>
                        } @else {
                          <span>
                            {{ 'alert_messages.window_is_fully_booked' | translate }}
                          </span>
                        }
                        <ng-container *ngTemplateOutlet="eventLocation; context: { $implicit: eventInfo }"/>
                      </div>
                    }

                    @if (displayRegularData(eventInfo) && !isSlotBooked(eventInfo.id)) {
                      <span class="event-slot-bookedout col-lg-6">
                        @if (hideEventCapacity === 0) {
                        <div>
                          @if ((eventInfo.regular_capacity - eventInfo.regular_booking_count) === 1) {
                          <span [innerHTML]="('events.step2.regular_count_singular' | translate) | trustHtml"></span>
                          }
                          @if ((eventInfo.regular_capacity - eventInfo.regular_booking_count) > 1) {
                          <span
                            [innerHTML]="('events.step2.regular_count_plural' | translate: {number: (eventInfo.regular_capacity - eventInfo.regular_booking_count)}) | trustHtml"></span>
                          }
                        </div>
                        <div class="">
                          @if ((eventInfo.waitinglist_capacity - eventInfo.waitinglist_booking_count) === 1) {
                          <span [innerHTML]="('events.step2.waiting_count_singular' | translate) | trustHtml"></span>
                          }
                          @if ((eventInfo.waitinglist_capacity - eventInfo.waitinglist_booking_count) > 1) {
                          <span
                            [innerHTML]="('events.step2.waiting_count_plural' | translate: {number: (eventInfo.waitinglist_capacity - eventInfo.waitinglist_booking_count)}) | trustHtml">
                          </span>
                          }
                        </div>

                        }
                        @if (hideEventCapacity === 1) {
                          <div>
                            <span>{{ 'events.step2.free_seats' | translate }}</span>
                          </div>
                          @if ((eventInfo.waitinglist_capacity - eventInfo.waitinglist_booking_count) >= 1) {
                            <div>
                              <span>{{ 'events.step2.free_waiting_seats' | translate }}</span>
                            </div>
                          }
                        }
                        <ng-container *ngTemplateOutlet="eventLocation; context: { $implicit: eventInfo }"/>
                      </span>
                    }

                  </app-cw-checkbox-radio-button>
                </div>
                <div class="next-arrow-container">
                  @if (isTerminInCart( eventInfo.id )) {
                    <span (click)="gotoNextPage()">
                      <fa-icon class="icon cbw-next-arrow-button d-flex" icon="chevron-circle-right"></fa-icon>
                    </span>
                  }
                </div>
              </div>
            </ng-container>
          }

          @if (!loadMoreSlots && eventOnDate?.length > 0 && eventOnDate?.length > 10) {
            <div class="d-flex justify-content-center load-more-button mt-4">
              <a class="load-more-link" href="javascript:void(0)" (click)="loadMore()">
                {{ 'load_more' | translate }}
              </a>
            </div>
          }
        </div>
      } @else {
        <app-alert text="{{ 'events.step1.noFreeSlots' | translate }}" type="danger" />
      }
    } @else {
      @if (noEvents) {
        <app-loader [loaderType]="eventConstants.EVENT_LOADER" />
      }
    }
  </div>
}

<ng-template #eventLocation let-eventInfo>
  @if (showWidgetSlotLocation) {
    <div class="cbw-summary-date-time d-flex mt-1">
      @if (eventInfo?.location) {
        <img class="location-icon" [src]="deployUrl + '/assets/images/location.svg'" />{{ eventInfo.location }}
      } @else {
        @if (
          [1, 12].includes(eventState?.meeting_type_id) &&
          eventState?.place?.trim() !== ',' &&
          (eventState?.place | isEmptyString)
        ) {
          <img class="location-icon" [src]="deployUrl + '/assets/images/location.svg'" />{{ eventState?.place }}
        } @else if (![1, 12]?.includes(eventState?.meeting_type_id) && eventState?.meeting_type_id && eventState?.meeting_type) {
          <img class="location-icon" [src]="deployUrl + '/assets/images/location.svg'" />{{ eventState.meeting_type | translation: 'name' : lang }}
        }
      }
    </div>
  }
</ng-template>
