import { NgClass, TitleCasePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { DATE_FORMAT, DATE_ISO_FORMAT, DATE_PIPE_WITH_COMMA, DATE_TIME_FORMAT, TIME_FORMAT } from '../../../../constants/date.constants';
import { TRANSLATION_TEMPLATES } from '../../../../constants/translation-templates-constants';
import { CustomFieldChildEvent } from '../../../../db-models/appointment-custom-fields-dao';
import { CouponDbModel } from '../../../../db-models/coupon-db.model';
import { CustomBookingMessageTemplate } from '../../../../db-models/widget-conf-dao';
import { EventCartItem, GuestModel } from '../../../../models/cart.model';
import { Partner } from '../../../../models/global';
import { EventState } from '../../../../models/state.model';
import { CustomEventService } from '../../../../services/custom-event.service';
import { CustomFieldService } from '../../../../services/custom-field.service';
import { FormsService } from '../../../../services/forms.service';
import { LocalStorageService } from '../../../../services/local-storage.service';
import { LoggerService } from '../../../../services/logger.service';
import { WidgetUtilService } from '../../../../services/widget-util.service';
import { NgxClampComponent } from '../../../../shared/components/ngx-clamp/ngx-clamp.component';
import { CalioCurrencyPipe } from '../../../../shared/pipes/calio-currency.pipe';
import { DateUtcPipe } from '../../../../shared/pipes/date_utc.pipe';
import { EventReplaceHourPipe } from '../../../../shared/pipes/event-replace-hour.pipe';
import { EventReplaceMinutePipe } from '../../../../shared/pipes/event-replace-minute.pipe';
import { IsEmptyStringPipe } from '../../../../shared/pipes/is-empty-string.pipe';
import { PricePipe } from '../../../../shared/pipes/price.pipe';
import { ReplaceCommaPipe } from '../../../../shared/pipes/replace-comma.pipe';
import { TranslationPipe } from '../../../../shared/pipes/translation.pipe';
import { CwCardHeaderComponent } from '../../../common/theme/cw-card-header/cw-card-header.component';
import { CwNewCardComponent } from '../../../common/theme/cw-new-card/cw-new-card.component';

@Component({
  selector: 'app-event-cart-overview',
  templateUrl: './event-cart-overview.component.html',
  styleUrls: ['./event-cart-overview.component.scss'],
  standalone: true,
  imports: [CwNewCardComponent, CwCardHeaderComponent, NgxClampComponent, NgClass, TitleCasePipe, EventReplaceHourPipe, EventReplaceMinutePipe, PricePipe, ReplaceCommaPipe, DateUtcPipe, TranslateModule, TranslationPipe, CalioCurrencyPipe, IsEmptyStringPipe]
})
export class EventCartOverviewComponent implements OnInit, OnChanges {

  @Input() additionalGuests: GuestModel[] = [];
  @Input() cart: EventCartItem[];
  @Input() lang: string;
  @Input() partner: Partner;
  @Input() totalCartDuration: number;
  @Input() totalCartPrice: number;
  @Input() cartLsName: string;
  @Input() coupon: CouponDbModel;
  @Input() courtesyForm: string;
  @Input() finalCouponDiscount: number = Number(0);
  @Input() eventState: EventState;
  @Input() widgetTemplates: CustomBookingMessageTemplate[] = [];

  @Output() removeFromCartEvent = new EventEmitter<string>();

  private deviceDetectorService = inject(DeviceDetectorService);
  private customFieldService = inject(CustomFieldService);
  private formsService = inject(FormsService);
  private localStorageService = inject(LocalStorageService);
  private translateService = inject(TranslateService);
  private customEventService = inject(CustomEventService);
  private widgetUtilService = inject(WidgetUtilService);
  private destroyRef = inject(DestroyRef);

  readonly dateFormat = DATE_FORMAT;
  readonly dateTimeFormat = DATE_TIME_FORMAT;
  readonly datePipeWithComma = DATE_PIPE_WITH_COMMA;
  readonly timeFormat = TIME_FORMAT;
  readonly dateIsoFormat = DATE_ISO_FORMAT;
  readonly deployUrl = environment.deployUrl;
  readonly templateContent = TRANSLATION_TEMPLATES;

  templateTitle: CustomBookingMessageTemplate;
  templateSubTitle: CustomBookingMessageTemplate;
  isMobile: boolean;
  selectedChildEvent: CustomFieldChildEvent;
  selectedChildEventLabel: string;
  toggleCustomFieldChildEventSubscription: Subscription;
  finalTotal: number;
  showWidgetSlotLocation = this.widgetUtilService.getWidgetConf().show_widget_slot_location;

  constructor(

  ) {
    this.translateService.onLangChange.subscribe(language => this.lang = language.lang);
  }

  ngOnInit(): void {
    this.isMobile = this.deviceDetectorService.isMobile();

    this.toggleCustomFieldChildEventSubscription = this.customFieldService.toggleCustomFieldChildEvent
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(result => {
        this.selectedChildEvent = result.childEvent;
        this.selectedChildEventLabel = result.label;

        if (this.selectedChildEvent) {
          this.getSlots();
        } else {
          this.selectedChildEvent = undefined;
          this.selectedChildEventLabel = undefined;
          this.resetCartItemsToInitialState();
        }
      });
  }

  removeFromCart(slotId: any): void {
    this.removeFromCartEvent.next(slotId);
  }

  async getSlots(): Promise<void> {
    for (const cart of this.cart) {
      await new Promise(resolve => {
        const date = moment(cart.date).format(this.dateIsoFormat);
        this.formsService.getSlots(this.selectedChildEvent.uuid, date)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: dates => {
              if (dates?.length) {
                const eventSlot = dates[0];
                const optionsCount = eventSlot.regular_capacity - eventSlot.regular_booking_count;
                if (optionsCount > 0) {
                  const tempSlotOptions = Array.from(Array(optionsCount), (_, x) => x + 1);
                  cart.tempSelectOptionLabel = this.selectedChildEvent.title;
                  cart.tempSelectLabel = this.selectedChildEventLabel;
                  cart.tempSlotOptions = tempSlotOptions;
                  if (!cart.selectedSlot) {
                    cart.selectedSlot = {
                      slot_id: eventSlot.id,
                      amount: tempSlotOptions[0]
                    };
                  }
                }
              }

              resolve(null);
            },
            error: (error: HttpErrorResponse) => LoggerService.error(error)
          });
      });
    }

    this.removeCartFromLocalStorage();
  }

  removeCartFromLocalStorage() {
    this.localStorageService.removeItem(this.cartLsName, () => this.updateCartInLocalStorage());
  }

  updateCartInLocalStorage(): void {
    this.localStorageService.setItem(this.cartLsName, this.cart, () => void (0));
  }

  resetCartItemsToInitialState(): void {
    for (const cart of this.cart) {
      cart.tempSelectOptionLabel = undefined;
      cart.tempSelectLabel = undefined;
      cart.tempSlotOptions = undefined;
      cart.selectedSlot = undefined;
    }
  }

  getFreeOrHidden(): string {
    for (const cart of this.cart) {
      if (cart.currentEvent.price === 0) {
        return 'free';
      }
    }

    return '';
  }

  getFinalTotal(): number {
    if (this.totalCartPrice > 0) {
      if (this.additionalGuests && this.additionalGuests.length > 0) {
        this.finalTotal = ((this.additionalGuests.length + 1) * Number(this.totalCartPrice)) - Number(this.finalCouponDiscount);
        if (this.finalTotal <= 0) {
          this.finalTotal = 0;
          return this.finalTotal;
        }
        return this.finalTotal;
      }
      this.finalTotal = Number(this.totalCartPrice) - Number(this.finalCouponDiscount);
      if (this.finalTotal <= 0) {
        this.finalTotal = 0;
        return this.finalTotal;
      }
      return this.finalTotal;
    } else {
      this.finalTotal = 0;
      return this.finalTotal;
    }
  }

  onAdditionalGuestChange(): void {
    this.customEventService.additionalGuestChangeEvent.emit({
      finalPrice: this.finalTotal,
      discount: this.finalCouponDiscount,
      additionalGuests: this.additionalGuests,
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getFinalTotal();
    this.onAdditionalGuestChange();

    if (changes?.widgetTemplates?.currentValue) {
      this.widgetTemplates?.length && this.setupTemplates();
    }
  }

  setupTemplates(): void {
    this.templateTitle = this.widgetTemplates.find(template => template.id === 124);
    this.templateTitle && (this.templateTitle.is_multi_language = 1);

    this.templateSubTitle = this.widgetTemplates.find(template => template.id === 125);
    this.templateSubTitle && (this.templateSubTitle.is_multi_language = 1);
  }
}

