import { DatePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { booleanAttribute, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import * as moment from 'moment';
import { environment } from '../../../../../environments/environment';
import { MONTHS } from '../../../../constants/agenda-view.constants';
import { DATE_FORMAT, DATE_ISO_FORMAT, DATE_PIPE_WITH_COMMA, DATE_TIME_FORMAT, TIME_FORMAT } from '../../../../constants/date.constants';
import { AgendaFilteredData, EventDateDao } from '../../../../db-models/event-date-dao';
import { EventCartItem } from '../../../../models/cart.model';
import { EventState } from '../../../../models/state.model';
import { UtilService } from '../../../../services/util.service';
import { WidgetUtilService } from '../../../../services/widget-util.service';
import { MatIconComponent } from '../../../../shared/components/mat-icon/mat-icon.component';
import { CalioMeetingTemplatePipe } from '../../../../shared/pipes/calio-meeting-template.pipe';
import { DateUtcPipe } from '../../../../shared/pipes/date_utc.pipe';
import { IsEmptyStringPipe } from '../../../../shared/pipes/is-empty-string.pipe';
import { KeepOrderKeyvaluePipe } from '../../../../shared/pipes/keep-order-keyvalue.pipe';
import { TrustHtmlPipe } from '../../../../shared/pipes/trust-html.pipe';
import { CwCheckboxRadioButtonComponent } from '../../../../widget/common/theme/cw-checkbox-radio-button/cw-checkbox-radio-button.component';

const pipes = [KeepOrderKeyvaluePipe, DateUtcPipe, TrustHtmlPipe, CalioMeetingTemplatePipe, IsEmptyStringPipe, DatePipe]
const components = [CwCheckboxRadioButtonComponent, MatIconComponent]
const modules = [TranslateModule, FontAwesomeModule]
const common = [NgClass, NgTemplateOutlet]

@Component({
  selector: 'app-event-agenda-view',
  standalone: true,
  imports: [...common, ...modules, ...pipes, ...components],
  templateUrl: './event-agenda-view.component.html',
  styleUrl: './event-agenda-view.component.scss'
})
export class EventAgendaViewComponent {

  @Input() eventState: EventState;
  @Input('eventOnYear') set EventForYear(eventforYear: EventDateDao[]) {
    this.selectedMonth = undefined;
    this._eventforYearWithoutFilter = eventforYear;
    this.prepareObject(eventforYear);
  };
  @Input({ transform: booleanAttribute }) calendarLoaded: boolean;
  @Input({ transform: booleanAttribute }) noFreeSlots: boolean;
  @Input() bookedItems: EventCartItem[];
  @Input() cart: EventCartItem[];
  @Input() selectedDate: string;

  @Output() updateCartEvent = new EventEmitter<any>();
  @Output() gotoNextPageEvent = new EventEmitter<any>();
  @Output() viewYearChangedEvent = new EventEmitter<{ isNext: boolean, isPrevious: boolean }>();

  private widgetUtilService = inject(WidgetUtilService);
  private utilService = inject(UtilService);

  protected readonly monthsConst = MONTHS;
  protected readonly dateFormat = DATE_FORMAT;
  protected readonly dateTimeFormat = DATE_TIME_FORMAT;
  protected readonly datePipeWithComma = DATE_PIPE_WITH_COMMA;
  protected readonly timeFormat = TIME_FORMAT;
  private readonly dateIsoFormat = DATE_ISO_FORMAT;
  protected readonly deployUrl = environment.deployUrl;

  protected _eventforYear = {};
  protected _eventforYearWithoutFilter: EventDateDao[];
  protected selectedMonth: number;
  protected currentDate = this.utilService.dateToStr(new Date());
  protected maxDate = this.utilService.dateToStr(new Date(new Date().setFullYear(new Date().getFullYear() + 5)));

  hideEventCapacity = this.widgetUtilService.getWidgetConf().hide_event_capacity;
  showWidgetSlotLocation = this.widgetUtilService.getWidgetConf().show_widget_slot_location;

  private prepareObject(slots: EventDateDao[]): void {
    const filteredData: AgendaFilteredData = {};

    slots.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());

    slots.forEach(event => {
      const monthIndex = new Date(event.date).getUTCMonth() + 1;
      const eventDate = new Date(event.date).toISOString().split('T')[0];

      if (!filteredData[monthIndex]) {
        filteredData[monthIndex] = {};
      }

      if (!filteredData[monthIndex][eventDate]) {
        filteredData[monthIndex][eventDate] = [];
      }

      filteredData[monthIndex][eventDate].push(event);
    });

    this._eventforYear = filteredData;
  }

  protected filterWithMonth(month: number): void {
    this.selectedMonth === month ? this.selectedMonth = null : this.selectedMonth = month;
  }

  protected getNextYearSlots(): void {
    this.viewYearChangedEvent.emit({ isNext: true, isPrevious: false });
  }

  protected getPreviousYearSlots(): void {
    this.viewYearChangedEvent.emit({ isNext: false, isPrevious: true });
  }

  // Event slot component code

  protected displayRegularData(event: EventDateDao): boolean {
    return (event.regular_booking_count < event.regular_capacity);
  }

  protected displayWaitingData(event: EventDateDao): boolean {
    return (event.waitinglist_booking_count < event.waitinglist_capacity);
  }

  protected isSlotBooked(slotId: number): boolean {
    for (const i in this.bookedItems) {
      if (this.bookedItems[i]['slotId'] === slotId) {
        return true;
      }
    }
    return false;
  }

  protected isTerminInCart(slotId: number): boolean {
    if (this.cart != null) {
      for (let i = this.cart.length - 1; i >= 0; i--) {
        if (this.cart[i].slotId === slotId) {
          return true;
        }
      }
    }
    return false;
  }

  protected updateCart(event: any, eventInfo: EventDateDao) {
    if (!this.displayRegularData(eventInfo)) {
      if (this.displayWaitingData(eventInfo)) {
        eventInfo.isWaitingList = true;
      }
    }

    this.updateCartEvent.emit({ event, eventInfo });
  }

  protected gotoNextPage(): void {
    this.gotoNextPageEvent.emit();
  }

  protected checkIfStartEndDatesAreEqual(startDate: Date, endDate: Date): boolean {
    return moment(moment(startDate).format(this.dateIsoFormat)).isSame(moment(moment(endDate).format(this.dateIsoFormat)));
  }
}
